var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("text-field-label", {
                    staticClass: "mr-3",
                    attrs: { label: _vm.$t("salepage.link.title") }
                  }),
                  _vm.salePageUrl
                    ? _c(
                        "a",
                        {
                          staticClass: "text-subtitle-1 mt-1 mb-2",
                          attrs: {
                            href:
                              "https://" + _vm.salePageUrl + ".nativewhale.com",
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                "https://" +
                                  _vm.salePageUrl +
                                  ".nativewhale.com"
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("link-sale-page-update")
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("toggle-form", {
                        attrs: {
                          text: _vm.$t(
                            "salepage.product.field.bestSeller.label"
                          ),
                          state: _vm.state.salePageBestSeller
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("salePageBestSeller")
                          }
                        },
                        model: {
                          value: _vm.form.salePageBestSeller,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "salePageBestSeller", $$v)
                          },
                          expression: "form.salePageBestSeller"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("toggle-form", {
                        attrs: {
                          text: _vm.$t(
                            "salepage.product.field.newProduct.label"
                          ),
                          state: _vm.state.salePageNewArrival
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("salePageNewArrival")
                          }
                        },
                        model: {
                          value: _vm.form.salePageNewArrival,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "salePageNewArrival", $$v)
                          },
                          expression: "form.salePageNewArrival"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("select-form", {
                staticClass: "stock-input-style",
                attrs: {
                  text: _vm.$t("salepage.warehouse.field.storage.label"),
                  placeholder: _vm.$t(
                    "salepage.warehouse.field.storage.placeholder"
                  ),
                  items: _vm.stocks,
                  "item-text": "name",
                  "item-value": function(item) {
                    return Number(item.id)
                  },
                  state: _vm.state.salePageFromStock,
                  attach: true
                },
                on: {
                  input: function($event) {
                    return _vm.submit("salePageFromStock")
                  }
                },
                model: {
                  value: _vm.form.salePageFromStock,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "salePageFromStock", $$v)
                  },
                  expression: "form.salePageFromStock"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("salepage.facebook.title") }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("text-field-form", {
                        attrs: {
                          text: _vm.$t("salepage.facebook.field.pageId.label"),
                          placeholder: _vm.$t(
                            "salepage.facebook.field.pageId.placeholder"
                          ),
                          state: _vm.state.facebookPageId
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("facebookPageId")
                          }
                        },
                        model: {
                          value: _vm.form.facebookPageId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "facebookPageId", $$v)
                          },
                          expression: "form.facebookPageId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("text-field-form", {
                        attrs: {
                          text: _vm.$t("salepage.facebook.field.message.label"),
                          placeholder: _vm.$t(
                            "salepage.facebook.field.message.placeholder"
                          ),
                          state: _vm.state.facebookMessengerGreetingMsg
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("facebookMessengerGreetingMsg")
                          }
                        },
                        model: {
                          value: _vm.form.facebookMessengerGreetingMsg,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "facebookMessengerGreetingMsg",
                              $$v
                            )
                          },
                          expression: "form.facebookMessengerGreetingMsg"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("salepage.banner.title") }
              })
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("salepage.banner.field.banner1.label")
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("upload", {
                            attrs: {
                              value: _vm.form.salePageBanner1Url,
                              name: "salePageBanner1",
                              "show-delete": ""
                            },
                            on: {
                              remove: function($event) {
                                return _vm.submit("removeSalePageBanner1")
                              },
                              submit: function($event) {
                                return _vm.upload("salePageBanner1", $event)
                              }
                            }
                          }),
                          _vm.loading.salePageBanner1
                            ? _c(
                                "div",
                                { staticClass: "text-center ma-3" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      size: 25,
                                      width: 3,
                                      indeterminate: ""
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("text-field-label", {
                        attrs: {
                          label: _vm.$t("salepage.banner.field.banner2.label")
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "relative" },
                        [
                          _c("upload", {
                            attrs: {
                              value: _vm.form.salePageBanner2Url,
                              name: "salePageBanner2",
                              "show-delete": ""
                            },
                            on: {
                              remove: function($event) {
                                return _vm.submit("removeSalePageBanner2")
                              },
                              submit: function($event) {
                                return _vm.upload("salePageBanner2", $event)
                              }
                            }
                          }),
                          _vm.loading.salePageBanner2
                            ? _c(
                                "div",
                                { staticClass: "text-center ma-3" },
                                [
                                  _c("v-progress-circular", {
                                    attrs: {
                                      size: 25,
                                      width: 3,
                                      indeterminate: ""
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                staticClass: "mr-3",
                attrs: { label: _vm.$t("salepage.detail.title") }
              }),
              _c(
                "div",
                [
                  _vm.salePageLoading
                    ? _c("v-progress-circular", {
                        attrs: { size: "16", width: "2", indeterminate: "" }
                      })
                    : _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [
                          _c("v-img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.salePageIcon && !_vm.salePageTimeout,
                                expression: "salePageIcon && !salePageTimeout"
                              }
                            ],
                            attrs: {
                              src: _vm.salePageIcon,
                              height: "16",
                              eager: "",
                              contain: ""
                            }
                          })
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", [
            _c(
              "div",
              { attrs: { id: "salepage-editor" } },
              [
                _c("editor", {
                  attrs: { "api-key": _vm.editorAPIKey, init: _vm.editorInit },
                  on: {
                    onChange: function($event) {
                      return _vm.submit("salePageText")
                    }
                  },
                  model: {
                    value: _vm.form.salePageText,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "salePageText", $$v)
                    },
                    expression: "form.salePageText"
                  }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }