var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "320px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-list-item",
                _vm._b(
                  {
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.$_.delay(on.click, 0, $event)
                      }
                    }
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                [
                  _c("v-list-item-subtitle", [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.$t("salepage.unpublish.btn")) + " "
                      )
                    ])
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "pt-6 px-6 pb-0" }),
          _c("v-card-text", { staticClass: "px-6" }, [
            _c(
              "div",
              { staticClass: "snt-salepage-unpublish__action" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { "x-small": "", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.setDialog(false)
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "#6C6C6C", size: "14" } }, [
                      _vm._v(" mdi-close ")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-center mb-4" },
              [
                _c("v-icon", { attrs: { color: "error", size: "53" } }, [
                  _vm._v(" mdi-lan-disconnect ")
                ])
              ],
              1
            ),
            _c("div", { staticClass: "snt-salepage-unpublish__title" }, [
              _vm._v(" " + _vm._s(_vm.$t("salepage.unpublish.title")) + " ")
            ]),
            _c("div", { staticClass: "snt-salepage-unpublish__description" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("salepage.unpublish.description")) + " "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pb-6 px-6" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "snt-btn--rounded px-4",
                  attrs: { text: "", rounded: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "snt-btn--rounded px-4",
                  attrs: {
                    loading: _vm.submitting,
                    color: "error",
                    depressed: "",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.unpublish()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("salepage.unpublish.btn")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }