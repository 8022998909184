<template>
  <v-sheet
    class="salepage-home"
    dark>
    <v-row>
      <v-col
        lg="3"
        md="4"
        sm="12"
        align-self="center">
        <v-img
          :src="whale2png"
          class="mx-auto"
          width="240"
          height="auto"
          contain />
      </v-col>
      <v-col
        lg="9"
        md="8"
        sm="12"
        align-self="center">
        <div class="salepage-home__content">
          <div class="salepage-home__title mb-4">
            เริ่มสร้างร้านค้าออนไลน์ของคุณ
          </div>
          <div class="salepage-home__subtitle mb-4">
            สร้างร้านค้าออนไลน์ไม่ยากเพิ่มช่องทางการขายสินค้าให้กับคุณ เพียงแค่ไม่นาน
          </div>
          <v-btn
            :href="`${shipnityUrl}/sale_page`"
            class="salepage-home__btn"
            color="primary"
            outlined
            x-large>
            <v-icon
              size="24"
              left>
              mdi-home
            </v-icon>
            เริ่มสร้าง
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import whale2png from '@/assets/img/salepage/2whale.png'

export default {
  data () {
    return {
      whale2png,
      shipnityUrl: process.env.VUE_APP_SHIPNITY
    }
  }
}
</script>

<style scoped>
  .salepage-home {
    background: linear-gradient(270deg, #1BA7E1 17.87%, #14B7FB 100%);
    padding: 32px;
    border-radius: 12px;
  }
  .salepage-home__content {
    padding: 38px 12px;
  }
  .salepage-home__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  }
  .salepage-home__subtitle {
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
  }
  .salepage-home__btn {
    border-radius: 14px;
  }
</style>
