<template>
  <v-dialog
    v-model="dialog"
    max-width="340px"
    @input="onDialog($event)">
    <template #activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('salepage.theme.btn') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card
      color="#FBFBFB"
      flat>
      <v-card-title class="px-4">
        <title-label :label="$t('salepage.theme.title')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4 pb-0">
        <v-form
          ref="form"
          v-model="valid"
          :readonly="submitting"
          @submit.prevent="submit()">
          <text-field-label :label="$t('salepage.theme.color')" />
          <div class="d-flex column-gap-2">
            <snt-color-picker v-model="form.colorPrimary" />
            <snt-color-picker v-model="form.colorSecondary" />
            <snt-color-picker v-model="form.colorAccent" />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          class="px-4"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!valid"
          class="px-4"
          color="primary"
          rounded
          depressed
          @click="submit()">
          {{ $t('app.btn.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_SALEPAGE } from '@/resources/graphql'
import SntColorPicker from '@/components/Form/SntColorPicker.vue'

export default {
  components: {
    SntColorPicker
  },
  props: {
    itemId: {
      type: String,
      required: true
    },
    itemCustomConfig: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      form: {
        colorPrimary: '',
        colorSecondary: '',
        colorAccent: ''
      }
    }
  },
  computed: {
    customConfig () {
      return this.itemCustomConfig || {
        theme: {
          color: {
            primary: '#ffffff',
            secondary: '#ffffff',
            accent: '#ffffff'
          }
        }
      }
    }
  },
  methods: {
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const customConfig = this.$_.defaultsDeep({
        theme: {
          color: {
            primary: this.form.colorPrimary,
            secondary: this.form.colorSecondary,
            accent: this.form.colorAccent
          }
        }
      }, this.customConfig)
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGE,
        variables: {
          input: {
            id: this.itemId,
            customConfig
          }
        }
      })
      this.setDialog(false)
      this.submitting = false
    },
    clearError (field) {
      this.error[field] = []
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    onDialog (dialog) {
      if (dialog) {
        const { theme: { color } } = this.customConfig
        this.form.colorPrimary = color.primary
        this.form.colorSecondary = color.secondary
        this.form.colorAccent = color.accent
      }
    }
  }
}
</script>

<style scoped>
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .card-style {
    background: #EAEEF5;
    border: 1px dashed #D5D5D5;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .font-style {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #A2AAB8;
  }
</style>
