var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "salepage-home", attrs: { dark: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "3", md: "4", sm: "12", "align-self": "center" } },
            [
              _c("v-img", {
                staticClass: "mx-auto",
                attrs: {
                  src: _vm.whale2png,
                  width: "240",
                  height: "auto",
                  contain: ""
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "9", md: "8", sm: "12", "align-self": "center" } },
            [
              _c(
                "div",
                { staticClass: "salepage-home__content" },
                [
                  _c("div", { staticClass: "salepage-home__title mb-4" }, [
                    _vm._v(" เริ่มสร้างร้านค้าออนไลน์ของคุณ ")
                  ]),
                  _c("div", { staticClass: "salepage-home__subtitle mb-4" }, [
                    _vm._v(
                      " สร้างร้านค้าออนไลน์ไม่ยากเพิ่มช่องทางการขายสินค้าให้กับคุณ เพียงแค่ไม่นาน "
                    )
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "salepage-home__btn",
                      attrs: {
                        href: _vm.shipnityUrl + "/sale_page",
                        color: "primary",
                        outlined: "",
                        "x-large": ""
                      }
                    },
                    [
                      _c("v-icon", { attrs: { size: "24", left: "" } }, [
                        _vm._v(" mdi-home ")
                      ]),
                      _vm._v(" เริ่มสร้าง ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }