var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "340px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-list-item",
                _vm._b(
                  {
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.$_.delay(on.click, 0, $event)
                      }
                    }
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                [
                  _c("v-list-item-subtitle", [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("salepage.domain.btn")) + " ")
                    ])
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#FBFBFB", flat: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "px-4" },
            [
              _c("title-label", {
                attrs: { label: _vm.$t("salepage.domain.title") }
              }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      size: 14,
                      width: 1,
                      color: "secondary",
                      indeterminate: ""
                    }
                  })
                ],
                1
              )
            : [
                _vm.cloudflareDomainList.length
                  ? [
                      _c(
                        "v-card-text",
                        { staticClass: "px-4 pb-0" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { readonly: _vm.submitting },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.submit()
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c("text-field-label", {
                                attrs: {
                                  label: _vm.$t(
                                    "salepage.domain.cloudflareName"
                                  )
                                }
                              }),
                              _c(
                                "div",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.cloudflareDomainList,
                                      "no-data-text": _vm.$t("app.noitem"),
                                      placeholder: _vm.$t(
                                        "salepage.domain.cloudflareName"
                                      ),
                                      "item-text": "name",
                                      "item-value": "name",
                                      "background-color": "#ffffff",
                                      outlined: "",
                                      dense: "",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.form.cloudflareDomainName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "cloudflareDomainName",
                                          $$v
                                        )
                                      },
                                      expression: "form.cloudflareDomainName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pb-4" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4",
                              attrs: {
                                color: "primary",
                                rounded: "",
                                text: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setDialog(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("app.btn.cancel")) + " "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4",
                              attrs: {
                                loading: _vm.submitting,
                                disabled: !_vm.valid,
                                color: "primary",
                                rounded: "",
                                depressed: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.submit()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("app.btn.submit")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "v-card-text",
                        { staticClass: "px-4 " },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "Connector", query: { tab: 2 } }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("salepage.domain.howtocloudflare")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }