<template>
  <div>
    <header-label
      :label="$t('salepage.header')"
      prepend-icon="$g_salepage" />
    <div
      v-if="loading"
      class="text-center">
      <v-progress-circular
        :size="14"
        :width="1"
        color="secondary"
        indeterminate
      />
    </div>
    <template v-else>
      <sale-pages v-if="betaNewWebsite" />
      <sale-page-basic
        v-else
        class="mt-4" />
    </template>
  </div>
</template>

<script>
import { GET_SETTING_SALEPAGE } from '@/resources/graphql'
import SalePageBasic from './components/SalePageBasic.vue'
import SalePages from './components/SalePages.vue'

export default {
  components: {
    SalePages,
    SalePageBasic
  },
  apollo: {
    setting: () => ({
      fetchPolicy: 'network-only',
      query: GET_SETTING_SALEPAGE,
      result ({ data: { setting } }) {
        this.betaNewWebsite = setting.betaNewWebsite
      }
    })
  },
  data () {
    return {
      betaNewWebsite: true
    }
  },
  computed: {
    loading () {
      return this.$apollo.queries.setting.loading
    }
  }
}
</script>

<style scoped>

</style>
