var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "snt-select",
    attrs: {
      value: _vm.value,
      items: _vm.items,
      multiple: _vm.multiple,
      label: _vm.label,
      placeholder: _vm.placeholder,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "no-data-text": _vm.noDataText,
      dense: _vm.dense,
      "menu-props": {
        "content-class": "snt-select__menu",
        origin: "center center",
        transition: "slide-y-transition",
        "offset-y": true
      },
      "background-color": "#ffffff",
      outlined: "",
      "hide-details": "",
      clearable: ""
    },
    on: {
      input: function($event) {
        return _vm.changeValues($event)
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "selection",
          fn: function(ref) {
            var item = ref.item
            var index = ref.index
            return [
              _vm.multiple
                ? _c(
                    "div",
                    [
                      index === 0
                        ? _c("v-chip", [
                            _c("span", [_vm._v(_vm._s(_vm.getText(item)))])
                          ])
                        : _vm._e(),
                      index === 1
                        ? _c("v-chip", [
                            _c("span", [_vm._v(_vm._s(_vm.getText(item)))])
                          ])
                        : _vm._e(),
                      index === 2
                        ? _c("span", { staticClass: "grey--text caption" }, [
                            _vm._v(
                              " (+" +
                                _vm._s(_vm.value.length - 2) +
                                " " +
                                _vm._s(_vm.othersText) +
                                ") "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")])
            ]
          }
        },
        _vm.multiple
          ? {
              key: "prepend-item",
              fn: function() {
                return [
                  _c(
                    "v-list",
                    { staticClass: "snt-select-multiple__all" },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                value: _vm.value.length,
                                "hide-details": "",
                                dense: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeValues([])
                                }
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: { value: 0, label: _vm.allText }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }