<template>
  <div>
    <v-card
      class="mb-3"
      flat>
      <v-card-text class="d-flex justify-space-between align-center">
        <div class="d-flex">
          <text-field-label
            :label="$t('salepage.link.title')"
            class="mr-3" />
          <a
            v-if="salePageUrl"
            class="text-subtitle-1 mt-1 mb-2"
            :href="`https://${salePageUrl}.nativewhale.com`"
            target="_blank">
            {{ `https://${salePageUrl}.nativewhale.com` }}
          </a>
        </div>
        <link-sale-page-update />
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      flat>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <toggle-form
              v-model="form.salePageBestSeller"
              :text="$t('salepage.product.field.bestSeller.label')"
              :state="state.salePageBestSeller"
              @input="submit('salePageBestSeller')" />
          </v-col>
          <v-col cols="12">
            <toggle-form
              v-model="form.salePageNewArrival"
              :text="$t('salepage.product.field.newProduct.label')"
              :state="state.salePageNewArrival"
              @input="submit('salePageNewArrival')" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      flat>
      <v-card-text>
        <select-form
          v-model="form.salePageFromStock"
          :text="$t('salepage.warehouse.field.storage.label')"
          :placeholder="$t('salepage.warehouse.field.storage.placeholder')"
          :items="stocks"
          :item-text="`name`"
          :item-value="(item) => Number(item.id)"
          :state="state.salePageFromStock"
          :attach="true"
          class="stock-input-style"
          @input="submit('salePageFromStock')" />
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      flat>
      <v-card-title>
        <title-label :label="$t('salepage.facebook.title')" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <text-field-form
              v-model="form.facebookPageId"
              :text="$t('salepage.facebook.field.pageId.label')"
              :placeholder="$t('salepage.facebook.field.pageId.placeholder')"
              :state="state.facebookPageId"
              @input="submit('facebookPageId')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <text-field-form
              v-model="form.facebookMessengerGreetingMsg"
              :text="$t('salepage.facebook.field.message.label')"
              :placeholder="$t('salepage.facebook.field.message.placeholder')"
              :state="state.facebookMessengerGreetingMsg"
              @input="submit('facebookMessengerGreetingMsg')" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      flat>
      <v-card-title>
        <title-label :label="$t('salepage.banner.title')" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="6">
            <text-field-label :label="$t('salepage.banner.field.banner1.label')" />
            <div class="relative">
              <upload
                :value="form.salePageBanner1Url"
                name="salePageBanner1"
                show-delete
                @remove="submit('removeSalePageBanner1')"
                @submit="upload('salePageBanner1', $event)" />
              <div
                v-if="loading.salePageBanner1"
                class="text-center ma-3">
                <v-progress-circular
                  :size="25"
                  :width="3"
                  indeterminate />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <text-field-label :label="$t('salepage.banner.field.banner2.label')" />
            <div class="relative">
              <upload
                :value="form.salePageBanner2Url"
                name="salePageBanner2"
                show-delete
                @remove="submit('removeSalePageBanner2')"
                @submit="upload('salePageBanner2', $event)" />
              <div
                v-if="loading.salePageBanner2"
                class="text-center ma-3">
                <v-progress-circular
                  :size="25"
                  :width="3"
                  indeterminate />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-3"
      flat>
      <v-card-title>
        <title-label
          :label="$t('salepage.detail.title')"
          class="mr-3" />
        <div>
          <v-progress-circular
            v-if="salePageLoading"
            size="16"
            width="2"
            indeterminate />
          <transition
            v-else
            name="fade">
            <v-img
              v-show="salePageIcon && !salePageTimeout"
              :src="salePageIcon"
              height="16"
              eager
              contain />
          </transition>
        </div>
      </v-card-title>
      <v-card-text>
        <div id="salepage-editor">
          <editor
            v-model="form.salePageText"
            :api-key="editorAPIKey"
            :init="editorInit"
            @onChange="submit('salePageText')"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import LinkSalePageUpdate from '@/views/SalePage/components/LinkSalePageUpdate.vue'
import Upload from '@/components/Form/Upload.vue'
import { GET_ME, GET_SETTING_SALEPAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'SalePage',
  apollo: {
    me: () => ({
      query: GET_ME,
      result ({ data: { me } }) {
        this.stocks = me.stocks
      }
    }),
    setting: () => ({
      fetchPolicy: 'network-only',
      query: GET_SETTING_SALEPAGE,
      result ({ data: { setting } }) {
        this.setSalePageUrl(setting.shopurl)
        this.form.salePageBestSeller = setting.salePageBestSeller
        this.form.salePageNewArrival = setting.salePageNewArrival
        this.form.salePageFromStock = setting.salePageFromStock
        this.form.facebookPageId = setting.facebookPageId
        this.form.facebookMessengerGreetingMsg = setting.facebookMessengerGreetingMsg
        this.form.salePageBanner1Url = setting.salePageBanner1Url
        this.form.salePageBanner2Url = setting.salePageBanner2Url
        this.form.salePageText = setting.salePageText
        this.setLoading(false)
      }
    })
  },
  components: {
    LinkSalePageUpdate,
    Upload,
    Editor
  },
  data () {
    return {
      editorAPIKey: process.env.VUE_APP_TINYMCE_KEY,
      editorInit: {
        setup (editor) {
          editor.ui.registry.addContextToolbar('imagealignment', {
            predicate (node) {
              return node.nodeName.toLowerCase() === 'img'
            },
            items: '',
            position: 'node',
            scope: 'node'
          })
          editor.ui.registry.addContextToolbar('textselection', {
            predicate () {
              return !editor.selection.isCollapsed()
            },
            items: 'fontsizeselect forecolor | bold italic | \\'
              + 'alignleft aligncenter alignright alignjustify | \\'
              + 'bullist numlist outdent indent | link image',
            position: 'selection',
            scope: 'node'
          })
        },
        height: 600,
        menubar: false,
        statusbar: false,
        toolbar: 'fontsizeselect forecolor | bold italic | \\'
              + 'alignleft aligncenter alignright alignjustify | \\'
              + 'bullist numlist outdent indent | link image | code',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px'
      },
      state: {
        salePageBestSeller: 'ready',
        salePageNewArrival: 'ready',
        salePageFromStock: 'ready',
        facebookPageId: 'ready',
        facebookMessengerGreetingMsg: 'ready',
        salePageText: 'ready'
      },
      form: {
        salePageBestSeller: null,
        salePageNewArrival: null,
        salePageFromStock: null,
        facebookPageId: null,
        facebookMessengerGreetingMsg: null,
        salePageBanner1: null,
        removeSalePageBanner1: true,
        salePageBanner2: null,
        removeSalePageBanner2: true,
        salePageText: null
      },
      stocks: [],
      files: [],
      loading: {
        salePageBanner1: false,
        salePageBanner2: false
      },
      salePageTimeout: false
    }
  },
  computed: {
    ...mapGetters({
      salePageUrl: 'Setting/salePageUrl'
    }),
    salePageLoading () {
      return this.state.salePageText === 'loading'
    },
    salePageIcon () {
      switch (this.state.salePageText) {
        case 'success':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        case 'error':
          return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
        default: return null
      }
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setSalePageUrl: 'Setting/setSalePageUrl',
      setLoading: 'Notification/setLoading'
    }),
    async upload (field, objFile) {
      this.loading[field] = true
      const { data: { updateSetting } } = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: objFile.file
          }
        },
        context: {
          hasUpload: true
        }
      })
      this.loading[field] = false
      this.form[field] = updateSetting[field]
    },
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.form[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>
  #salepage-editor *[contentEditable="true"]:focus,
  #salepage-editor *[contentEditable="true"]:hover {
    outline: 2px solid #2276d2;
  }
  .stock-input-style .v-select {
    max-width: 400px;
  }
</style>

<style scoped>

</style>
