var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    { attrs: { color: "primary", rounded: "", depressed: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(" mdi-square-edit-outline ")
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("salepage.link.btn.edit")) + " ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("salepage.link.updateLinkTitle") }
              }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [
                    _vm._v(" mdi-close ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { readonly: _vm.isReadonly, "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("text-field-label", {
                            attrs: {
                              label: _vm.$t("salepage.link.field.name.label"),
                              required: ""
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t(
                                "salepage.link.field.name.placeholder"
                              ),
                              "error-messages": _vm.error.shopurl,
                              dense: "",
                              outlined: "",
                              required: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.markAsDirty($event)
                              }
                            },
                            model: {
                              value: _vm.form.shopurl,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "shopurl", $$v)
                              },
                              expression: "form.shopurl"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("salepage.link.field.name.hint")) +
                              " "
                          )
                        ]),
                        _c("div", { staticClass: "primary--text mb-1" }, [
                          _vm._v(
                            " https://" +
                              _vm._s(_vm.form.shopurl) +
                              ".nativewhale.com "
                          )
                        ]),
                        _c("div", { staticClass: "primary--text mb-1" }, [
                          _vm._v(
                            " https://www." +
                              _vm._s(_vm.domainName) +
                              "/tracking/" +
                              _vm._s(_vm.form.shopurl) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c("save-change-button", {
                staticClass: "mr-1",
                attrs: {
                  state: _vm.state,
                  disabled: !_vm.isDirty || !_vm.valid
                },
                on: {
                  click: function($event) {
                    return _vm.submit()
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }