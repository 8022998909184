var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "snt-salepage-card", attrs: { width: 290, outlined: "" } },
    [
      _c(
        "v-img",
        {
          staticClass: "snt-salepage-card__img",
          attrs: { src: _vm.itemSnapshotUrl, height: "150px" }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.itemPublished
                ? _c("v-chip", { attrs: { color: "success", small: "" } }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.$t("app.published")) + " ")
                    ])
                  ])
                : _vm._e(),
              _c("v-spacer"),
              _c("sale-page-action", {
                attrs: {
                  "item-id": _vm.itemId,
                  "item-domain-url": _vm.itemDomainUrl,
                  "item-snapshot-url": _vm.itemSnapshotUrl,
                  "item-domain": _vm.itemDomain,
                  "item-custom-config": _vm.itemCustomConfig,
                  "item-stock": _vm.itemStock,
                  "item-custom-domain": _vm.itemCustomDomain
                }
              })
            ],
            1
          )
        ]
      ),
      _c("v-card-text", { staticClass: "pa-3" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("div", { staticClass: "w-100 align-center mr-1" }, [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "domain-link",
                      attrs: {
                        href: _vm.itemCustomDomainUrl || _vm.itemDomainUrl,
                        target: "_blank"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.itemDomain) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:copy",
                          value: _vm.itemCustomDomainUrl || _vm.itemDomainUrl,
                          expression: "itemCustomDomainUrl || itemDomainUrl",
                          arg: "copy"
                        },
                        {
                          name: "clipboard",
                          rawName: "v-clipboard:success",
                          value: _vm.onCopy,
                          expression: "onCopy",
                          arg: "success"
                        }
                      ],
                      attrs: { icon: "", "x-small": "" }
                    },
                    [
                      _c("v-icon", { attrs: { size: "14" } }, [
                        _vm._v(" mdi-content-copy ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", [
                _c("span", { staticClass: "font-weight-medium" }, [
                  _vm._v("เผยแพร่ล่าสุด: ")
                ]),
                _vm.itemPublishedAt
                  ? _c("span", { staticClass: "caption" }, [
                      _vm._v(_vm._s(_vm._f("formatTime")(_vm.itemPublishedAt)))
                    ])
                  : _c("span", [_vm._v("-")])
              ]),
              _c("div", [
                _c("span", { staticClass: "font-weight-medium" }, [
                  _vm._v("คลัง: ")
                ]),
                _c("span", [_vm._v(_vm._s(_vm.itemStock.name))])
              ])
            ]),
            _c("v-spacer"),
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      href:
                        _vm.shipnityUrl +
                        "/sale_page/sites/" +
                        _vm.itemId +
                        "/customize",
                      color: "primary",
                      depressed: "",
                      rounded: "",
                      small: ""
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("app.btn.edit")) + " ")]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }