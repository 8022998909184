<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    @input="onDialog($event)">
    <template #activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('salepage.config.btn') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card
      color="#FBFBFB"
      flat>
      <v-card-title>
        <title-label :label="$t('salepage.config.title')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :readonly="submitting"
          @submit.prevent="submit()">
          <text-field-label :label="$t('salepage.config.facebookPageId.label')" />
          <div class="mb-4">
            <v-text-field
              v-model="form.facebookPageId"
              :placeholder="$t('salepage.config.facebookPageId.placeholder')"
              autocomplete="off"
              background-color="#ffffff"
              hide-details
              outlined
              dense />
          </div>
          <text-field-label :label="$t('salepage.config.facebookMessage.label')" />
          <div class="mb-4">
            <v-text-field
              v-model="form.facebookMessengerGreetingMsg"
              :placeholder="$t('salepage.config.facebookMessage.placeholder')"
              autocomplete="off"
              background-color="#ffffff"
              hide-details
              outlined
              dense />
          </div>
          <text-field-label :label="$t('salepage.config.stock.label')" />
          <div class="mb-4">
            <v-text-field
              :value="`${itemStock.name} (${$t('salepage.config.stock.hint')})`"
              autocomplete="off"
              background-color="#ffffff"
              hide-details
              outlined
              dense
              disabled />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="px-4"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!valid"
          class="px-4"
          color="primary"
          rounded
          depressed
          @click="submit()">
          {{ $t('app.btn.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_SALEPAGE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: String,
      required: true
    },
    itemStock: {
      type: Object,
      default: undefined
    },
    itemCustomConfig: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      form: {
        facebookPixelTag: '',
        googleAnalyticsTag: '',
        facebookPageId: '',
        facebookMessengerGreetingMsg: ''
      }
    }
  },
  computed: {
    customConfig () {
      return this.$_.defaultsDeep(this.itemCustomConfig, {
        facebookPixelTag: '',
        googleAnalyticsTag: '',
        facebookPageId: '',
        facebookMessengerGreetingMsg: ''
      })
    }
  },
  methods: {
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const customConfig = this.$_.defaultsDeep(this.form, this.customConfig)
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGE,
        variables: {
          input: {
            id: this.itemId,
            customConfig
          }
        }
      })
      this.setDialog(false)
      this.submitting = false
    },
    clearError (field) {
      this.error[field] = []
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    onDialog (dialog) {
      if (dialog) {
        const { facebookPixelTag, googleAnalyticsTag, facebookPageId, facebookMessengerGreetingMsg } = this.customConfig
        this.form.facebookPixelTag = facebookPixelTag
        this.form.googleAnalyticsTag = googleAnalyticsTag
        this.form.facebookPageId = facebookPageId
        this.form.facebookMessengerGreetingMsg = facebookMessengerGreetingMsg
      }
    }
  }
}
</script>

<style scoped>
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .card-style {
    background: #EAEEF5;
    border: 1px dashed #D5D5D5;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .font-style {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #A2AAB8;
  }
</style>
