<template>
  <v-card
    :width="290"
    class="snt-salepage-card"
    outlined>
    <v-img
      :src="itemSnapshotUrl"
      class="snt-salepage-card__img"
      height="150px">
      <div class="d-flex">
        <v-chip
          v-if="itemPublished"
          color="success"
          small>
          <div>
            {{ $t('app.published') }}
          </div>
        </v-chip>
        <v-spacer />
        <sale-page-action
          :item-id="itemId"
          :item-domain-url="itemDomainUrl"
          :item-snapshot-url="itemSnapshotUrl"
          :item-domain="itemDomain"
          :item-custom-config="itemCustomConfig"
          :item-stock="itemStock"
          :item-custom-domain="itemCustomDomain" />
      </div>
    </v-img>
    <v-card-text class="pa-3">
      <div class="d-flex">
        <div class="w-100 align-center mr-1">
          <div class="d-flex">
            <a
              :href="itemCustomDomainUrl || itemDomainUrl"
              class="domain-link"
              target="_blank">
              {{ itemDomain }}
            </a>
            <v-btn
              v-clipboard:copy="itemCustomDomainUrl || itemDomainUrl"
              v-clipboard:success="onCopy"
              icon
              x-small>
              <v-icon size="14">
                mdi-content-copy
              </v-icon>
            </v-btn>
          </div>
          <div>
            <span class="font-weight-medium">เผยแพร่ล่าสุด: </span>
            <span
              v-if="itemPublishedAt"
              class="caption">{{ itemPublishedAt | formatTime }}</span>
            <span v-else>-</span>
          </div>
          <div>
            <span class="font-weight-medium">คลัง: </span>
            <span>{{ itemStock.name }}</span>
          </div>
        </div>
        <v-spacer />
        <div>
          <v-btn
            :href="`${shipnityUrl}/sale_page/sites/${itemId}/customize`"
            color="primary"
            depressed
            rounded
            small>
            {{ $t('app.btn.edit') }}
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import SalePageAction from './SalePageAction.vue'
// import * as htmlToImage from 'html-to-image'

export default {
  components: { SalePageAction },
  props: {
    itemId: {
      type: String,
      default: ''
    },
    itemDomainUrl: {
      type: String,
      default: ''
    },
    itemDomain: {
      type: String,
      default: ''
    },
    itemCustomDomain: {
      type: String,
      default: ''
    },
    itemCustomDomainUrl: {
      type: String,
      default: ''
    },
    itemPublishedAt: {
      type: String,
      default: undefined
    },
    itemPublished: {
      type: Boolean,
      default: undefined
    },
    itemStock: {
      type: Object,
      default: () => ({})
    },
    itemCustomConfig: {
      type: Object,
      default: () => ({})
    },
    itemSnapshotUrl: {
      type: [String],
      default: ''
    }
  },
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY
    }
  },
  async mounted () {
    // this.itemSnapshotUrl = await this.loadSnapshotUrl(this.itemDomainName)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Notification/setSnackbar'
    }),
    onCopy () {
      this.setSnackbar({
        value: true,
        timeout: 1500,
        multiLine: false,
        message: 'คัดลอกลิงค์'
      })
    }
    // async loadSnapshotUrl (url) {
    //   console.log(url)
    //   const iframe = document.createElement('iframe')
    //   iframe.src = url
    //   return htmlToImage.toBlob(iframe, {
    //     width: 800,
    //     height: 600
    //   }).then((dataUrl) => {
    //     if (dataUrl) {
    //       return URL.createObjectURL(dataUrl)
    //     }
    //     return null
    //   }).catch((error) => {
    //     console.error('oops, something went wrong!', error)
    //     return null
    //   })
    // }
  }
}
</script>

<style  scoped>
  .domain-link {
    text-decoration: none;
    margin-right: 4px;
  }
  .snt-salepage-card__img {
    background-color: #C9C9C9;
    text-align: end;
    padding: 8px;
  }
</style>
