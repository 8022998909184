<template>
  <v-dialog
    v-model="dialog"
    max-width="340px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('salepage.domain.btn') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card
      color="#FBFBFB"
      flat>
      <v-card-title class="px-4">
        <title-label :label="$t('salepage.domain.title')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <div
        v-if="loading"
        class="text-center">
        <v-progress-circular
          :size="14"
          :width="1"
          color="secondary"
          indeterminate
        />
      </div>
      <template v-else>
        <template v-if="cloudflareDomainList.length">
          <v-card-text class="px-4 pb-0">
            <v-form
              ref="form"
              v-model="valid"
              :readonly="submitting"
              @submit.prevent="submit()">
              <text-field-label :label="$t('salepage.domain.cloudflareName')" />
              <div>
                <v-select
                  v-model="form.cloudflareDomainName"
                  :items="cloudflareDomainList"
                  :no-data-text="$t('app.noitem')"
                  :placeholder="$t('salepage.domain.cloudflareName')"
                  item-text="name"
                  item-value="name"
                  background-color="#ffffff"
                  outlined
                  dense
                  clearable />
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer />
            <v-btn
              class="px-4"
              color="primary"
              rounded
              text
              @click="setDialog(false)">
              {{ $t('app.btn.cancel') }}
            </v-btn>
            <v-btn
              :loading="submitting"
              :disabled="!valid"
              class="px-4"
              color="primary"
              rounded
              depressed
              @click="submit()">
              {{ $t('app.btn.submit') }}
            </v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text class="px-4 ">
            <router-link :to="{ name: 'Connector', query: { tab: 2 }}">
              {{ $t('salepage.domain.howtocloudflare') }}
            </router-link>
          </v-card-text>
        </template>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { SELECT_SALEPAGE_CUSTOMDOMAIN, GET_SALEPAGE_DOMAINLIST, DELETE_SALEPAGE_CUSTOMDOMAIN } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: String,
      required: true
    },
    itemStock: {
      type: Object,
      default: () => ({})
    },
    itemCustomDomain: {
      type: String,
      default: ''
    }
  },
  apollo: {
    cloudflareDomainList: () => ({
      query: GET_SALEPAGE_DOMAINLIST,
      fetchPolicy: 'network-only',
      skip () {
        return !this.dialog || !this.itemStock.shop.cloudflareAuthEmail || !this.itemStock.shop.cloudflareAuthEmail
      },
      variables () {
        return {
          id: this.itemId
        }
      },
      update (data) {
        return data.salePage.stock.shop.cloudflareDomainList
      },
      result ({ data }) {
        const cloudflareAccountList = this.$_.head(data.salePage.stock.shop.cloudflareAccountList)
        if (cloudflareAccountList) {
          this.cloudflareAccountId = cloudflareAccountList.id
        }
      }
    })
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: false,
      cloudflareAccountId: null,
      cloudflareDomainList: [],
      form: {
        cloudflareDomainName: this.itemCustomDomain
      }
    }
  },
  computed: {
    loading () {
      return this.$apollo.queries.cloudflareDomainList.loading
    }
  },
  methods: {
    async submit () {
      if (!this.valid) return
      this.submitting = true
      if (this.form.cloudflareDomainName) {
        await this.$apollo.mutate({
          mutation: SELECT_SALEPAGE_CUSTOMDOMAIN,
          variables: {
            input: {
              id: this.itemId,
              cloudflareAccount: this.cloudflareAccountId,
              cloudflareDomain: this.form.cloudflareDomainName
            }
          }
        })
      } else if (this.itemCustomDomain) {
        await this.$apollo.mutate({
          mutation: DELETE_SALEPAGE_CUSTOMDOMAIN,
          variables: {
            input: {
              id: this.itemId
            }
          }
        })
      }
      this.setDialog(false)
      this.submitting = false
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
</style>
