var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("salepage.header"),
          "prepend-icon": "$g_salepage"
        }
      }),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 14,
                  width: 1,
                  color: "secondary",
                  indeterminate: ""
                }
              })
            ],
            1
          )
        : [
            _vm.betaNewWebsite
              ? _c("sale-pages")
              : _c("sale-page-basic", { staticClass: "mt-4" })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }