<template>
  <div>
    <sale-page-home v-if="countModifier === 1 && !salePages.nodes.length" />
    <div v-else-if="countModifier">
      <div class="d-flex column-gap-3 row-gap-2 flex-wrap mb-4">
        <div class="snt-salepage-search">
          <v-text-field
            :value="search"
            :placeholder="$t('app.form.search.placeholder')"
            prepend-inner-icon="mdi-magnify"
            autocomplete="off"
            background-color="#ffffff"
            hide-details
            outlined
            dense
            @input="debounceSetSearch($event)" />
        </div>
        <div class="snt-salepage-stock">
          <snt-select
            :value="stocksSelected"
            :items="stocks"
            :placeholder="$t('salepage.allStock')"
            :no-data-text="$t('app.noitem')"
            :all-text="$t('salepage.allStock')"
            :others-text="$t('app.others')"
            item-text="name"
            item-value="id"
            multiple
            dense
            @input="setStocks($event)" />
        </div>
        <v-spacer />
        <v-btn
          :href="`${shipnityUrl}/sale_page`"
          color="primary"
          depressed>
          {{ $t('salepage.create') }}
        </v-btn>
      </div>
      <div>
        <div class="d-flex flex-wrap column-gap-3 row-gap-3">
          <template v-for="item in salePages.nodes">
            <sale-page-card
              :key="`salepage-${item.id}`"
              v-bind="$_.prefix('item', item)"
              :item-snapshot-url="item.salePageTemplate.snapshotUrl"
              :item-custom-domain-url="replaceHTTPS(item.customDomain)"
              :item-domain-url="replaceSubdomain(item.domain)" />
          </template>
        </div>
        <v-container v-if="!salePages.nodes.length">
          <div class="no-data">
            <v-icon size="38">
              mdi-emoticon-cry-outline
            </v-icon>
            {{ $t('app.noitem') }}
          </div>
        </v-container>
      </div>
      <infinite-loading
        :identifier="infiniteId"
        @infinite="infiniteHandler($event)">
        <div slot="no-more" />
        <div slot="no-results" />
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { GET_SALEPAGES, GET_STOCKS } from '@/resources/graphql'
import SntSelect from '@/components/Form/SntSelect.vue'
import SalePageCard from './SalePageCard.vue'
import SalePageHome from './SalePageHome.vue'

export default {
  components: { SalePageHome, SalePageCard, SntSelect },
  apollo: {
    stocks: () => ({
      query: GET_STOCKS,
      variables () {
        return {
          page: 1,
          perPage: 1000
        }
      },
      update: ({ stocks }) => stocks.nodes
    }),
    salePages: () => ({
      query: GET_SALEPAGES,
      fetchPolicy: 'network-only',
      debounce: 300,
      variables () {
        return {
          page: 1,
          perPage: this.perPage,
          filter: {
            search: this.search,
            stockId: this.stocksSelected
          }
        }
      },
      result () {
        this.countModifier += 1
        this.infiniteId += 1
      }
    })
  },
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY,
      countModifier: 0,
      infiniteId: +new Date(),
      salePages: {
        nodes: [],
        hasNextPage: false
      },
      page: 1,
      perPage: 12,
      search: '',
      stocksSelected: []
    }
  },
  created () {
    this.debounceSetSearch = this.$_.debounce(this.setSearch, 500)
  },
  methods: {
    async infiniteHandler ($state) {
      if (this.salePages.hasNextPage) {
        this.page += 1
        await this.$apollo.queries.salePages.fetchMore({
          variables: {
            page: this.page,
            perPage: this.perPage,
            filter: {
              search: this.search
            }
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const { hasNextPage, nodes } = fetchMoreResult.salePages
            return ({
              salePages: {
                __typename: previousResult.salePages.__typename,
                nodes: this.$_.uniqBy([
                  ...previousResult.salePages.nodes,
                  ...nodes
                ], 'id'),
                hasNextPage
              }
            })
          }
        })
        $state.loaded()
      } else {
        $state.complete()
      }
    },
    setSearch (search = '') {
      this.page = 1
      this.search = search
    },
    setStocks (stocks) {
      this.page = 1
      this.stocksSelected = stocks
    },
    replaceSubdomain (subdomain) {
      const newUrl = this.replaceHTTPS(process.env.VUE_APP_SALEPAGE)
      const replace = `://${ subdomain }.`
      if (newUrl.match(/\.\w*\b/g).length > 1) {
          return newUrl.replace(/(:\/\/\w+\.)/, replace)
      }
      return newUrl.replace(/:\/\/(\w*\.)/, `${replace}$1`)
    },
    replaceHTTPS (url) {
      if (!url) return null
      let newUrl = url
      if (!/^\w*:\/\//.test(newUrl)) {
          newUrl = `https://${ newUrl }`
      }
      if (newUrl.match(/\.\w*\b/g).length <= 1) {
        return newUrl.replace(/^([^/]*)(\/\/)(.*)/, '$1//www.$3')
      }
      return newUrl
    }
  }
}
</script>

<style  scoped>
  .snt-salepage-list {
    margin: 0 -8px;
  }
  .snt-salepage-search {
    width: 400px;
  }
  .snt-salepage-stock {
    width: 300px;
  }
  .no-data {
    text-align: center;
    padding: 7rem 0;
    display: flex;
    flex-direction: column;
  }
</style>
