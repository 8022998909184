var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      on: {
        input: function($event) {
          return _vm.onDialog($event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-list-item",
                _vm._b(
                  {
                    attrs: { link: "" },
                    on: {
                      click: function($event) {
                        return _vm.$_.delay(on.click, 0, $event)
                      }
                    }
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                [
                  _c("v-list-item-subtitle", [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("salepage.config.btn")) + " ")
                    ])
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#FBFBFB", flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _c("title-label", {
                attrs: { label: _vm.$t("salepage.config.title") }
              }),
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" mdi-close ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { readonly: _vm.submitting },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit()
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("text-field-label", {
                    attrs: {
                      label: _vm.$t("salepage.config.facebookPageId.label")
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: _vm.$t(
                            "salepage.config.facebookPageId.placeholder"
                          ),
                          autocomplete: "off",
                          "background-color": "#ffffff",
                          "hide-details": "",
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.form.facebookPageId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "facebookPageId", $$v)
                          },
                          expression: "form.facebookPageId"
                        }
                      })
                    ],
                    1
                  ),
                  _c("text-field-label", {
                    attrs: {
                      label: _vm.$t("salepage.config.facebookMessage.label")
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          placeholder: _vm.$t(
                            "salepage.config.facebookMessage.placeholder"
                          ),
                          autocomplete: "off",
                          "background-color": "#ffffff",
                          "hide-details": "",
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.form.facebookMessengerGreetingMsg,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "facebookMessengerGreetingMsg",
                              $$v
                            )
                          },
                          expression: "form.facebookMessengerGreetingMsg"
                        }
                      })
                    ],
                    1
                  ),
                  _c("text-field-label", {
                    attrs: { label: _vm.$t("salepage.config.stock.label") }
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value:
                            _vm.itemStock.name +
                            " (" +
                            _vm.$t("salepage.config.stock.hint") +
                            ")",
                          autocomplete: "off",
                          "background-color": "#ffffff",
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          disabled: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "px-4",
                  attrs: { color: "primary", rounded: "", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.setDialog(false)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "px-4",
                  attrs: {
                    loading: _vm.submitting,
                    disabled: !_vm.valid,
                    color: "primary",
                    rounded: "",
                    depressed: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("app.btn.submit")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }