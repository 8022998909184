<template>
  <v-dialog
    v-model="dialog"
    max-width="340px"
    @input="onDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('salepage.rename.btn') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card
      color="#FBFBFB"
      flat>
      <v-card-title class="px-4">
        <title-label :label="$t('salepage.rename.title')" />
        <v-spacer />
        <v-icon
          small
          @click="setDialog(false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="px-4 pb-0">
        <v-form
          ref="form"
          v-model="valid"
          :readonly="submitting"
          @submit.prevent="submit()">
          <text-field-label :label="$t('salepage.rename.name')" />
          <div>
            <v-text-field
              v-model="form.name"
              :rules="rule.name"
              :error-messages="error.name.length ? [$t('app.validate.exist')] : []"
              :suffix="suffixDomain"
              autocomplete="off"
              background-color="#ffffff"
              autofocus
              outlined
              dense
              @input="clearError('name')" />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn
          class="px-4"
          color="primary"
          rounded
          text
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!valid"
          class="px-4"
          color="primary"
          rounded
          depressed
          @click="submit()">
          {{ $t('app.btn.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_SALEPAGE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: String,
      required: true
    },
    itemName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: true,
      form: {
        name: this.itemName
      },
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        name: []
      }
    }
  },
  computed: {
    suffixDomain () {
      const url = process.env.VUE_APP_SALEPAGE
      const host = url.split('.')
      return `.${host[1] }.${ host[2]}`
    }
  },
  methods: {
    async submit () {
      if (!this.valid) return
      this.submitting = true
      const { data: { updateSalePage } } = await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGE,
        variables: {
          input: {
            id: this.itemId,
            domain: this.form.name
          }
        }
      })
      if (updateSalePage.errors) {
        this.error.name = updateSalePage.errors
      } else {
        this.setDialog(false)
      }
      this.submitting = false
    },
    clearError (field) {
      this.error[field] = []
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    },
    onDialog (dialog) {
      if (dialog) {
        this.form.name = this.itemName
      }
    }
  }
}
</script>

<style scoped>
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .card-style {
    background: #EAEEF5;
    border: 1px dashed #D5D5D5;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .font-style {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #A2AAB8;
  }
</style>
