<template>
  <v-menu
    :close-on-content-click="true"
    content-class="snt-salepage-action__menu"
    min-width="135"
    origin="center center"
    transition="slide-y-transition"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="snt-salepage-action__btn"
        color="primary"
        small
        depressed
        outlined
        v-on="on">
        <v-icon size="30">
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>
    <v-list
      class="snt-salepage-action"
      outlined
      dense>
      <!-- <sale-page-publish-button :item-id="itemId" /> -->
      <sale-page-theme
        :item-id="itemId"
        :item-custom-config="itemCustomConfig" />
      <sale-page-rename
        :item-id="itemId"
        :item-name="itemDomain" />
      <sale-page-domain
        :item-id="itemId"
        :item-stock="itemStock"
        :item-custom-domain="itemCustomDomain" />
      <sale-page-config
        :item-id="itemId"
        :item-stock="itemStock"
        :item-custom-config="itemCustomConfig" />
      <sale-page-un-publish-button :item-id="itemId" />
      <sale-page-delete-button :item-id="itemId" />
    </v-list>
  </v-menu>
</template>

<script>
import SalePageConfig from './SalePageConfig.vue'
import SalePageDeleteButton from './SalePageDeleteButton.vue'
// import SalePagePublishButton from './SalePagePublishButton.vue'
import SalePageUnPublishButton from './SalePageUnPublishButton.vue'
import SalePageRename from './SalePageRename.vue'
import SalePageTheme from './SalePageTheme.vue'
import SalePageDomain from './SalePageDomain.vue'

export default {
  components: {
    SalePageDeleteButton,
    // SalePagePublishButton,
    SalePageRename,
    SalePageUnPublishButton,
    SalePageTheme,
    SalePageConfig,
    SalePageDomain
  },
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    itemDomainUrl: {
      type: String,
      default: ''
    },
    itemDomain: {
      type: String,
      default: ''
    },
    itemCustomConfig: {
      type: Object,
      default: () => ({})
    },
    itemStock: {
      type: Object,
      default: () => ({})
    },
    itemCustomDomain: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {

  }
}
</script>
<style>
  .snt-salepage-action .v-list-item--disabled .v-list-item__subtitle {
    color: #B1B1B1;
  }
</style>
<style scoped>
  .snt-salepage-action.v-list  {
    /* border-radius: 14px; */
    background: #ffffff;
  }
  .snt-salepage-action__menu {
    box-shadow: none;
  }
  .snt-salepage-action__btn.v-btn {
    background: #ffffff;
    min-width: 32px;
    padding: 0;
  }
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
</style>
