<template>
  <v-dialog
    v-model="dialog"
    width="320px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-bind="attrs"
        link
        @click="$_.delay(on.click, 0, $event)">
        <v-list-item-subtitle>
          <span> {{ $t('salepage.unpublish.btn') }} </span>
        </v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-salepage-unpublish__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="14">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon
            color="error"
            size="53">
            mdi-lan-disconnect
          </v-icon>
        </div>
        <div class="snt-salepage-unpublish__title">
          {{ $t('salepage.unpublish.title') }}
        </div>
        <div class="snt-salepage-unpublish__description">
          {{ $t('salepage.unpublish.description') }}
        </div>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-btn--rounded px-4"
          text
          rounded
          @click="setDialog(false)">
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          class="snt-btn--rounded px-4"
          color="error"
          depressed
          rounded
          @click="unpublish()">
          {{ $t('salepage.unpublish.btn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_SALEPAGE } from '@/resources/graphql'

export default {
  props: {
    itemId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false
    }
  },
  methods: {
    async unpublish () {
      this.submitting = true
      await this.$apollo.mutate({
        mutation: UPDATE_SALEPAGE,
        variables: {
          input: {
            id: this.itemId,
            published: false
          }
        }
      })
      this.submitting = false
      this.setDialog(false)
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-salepage-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-salepage-unpublish__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-salepage-unpublish__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-salepage-unpublish__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #B1B1B1;
  }
</style>
