<template>
  <v-dialog
    v-model="dialog"
    max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        rounded
        depressed
        v-bind="attrs"
        v-on="on">
        <v-icon
          left
          small>
          mdi-square-edit-outline
        </v-icon>
        {{ $t('salepage.link.btn.edit') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <title-label :label="$t('salepage.link.updateLinkTitle')" />
        <v-spacer />
        <v-btn
          icon
          small
          @click="closeDialog()"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          :readonly="isReadonly"
          lazy-validation
          @submit.prevent="submit()">
          <v-row>
            <v-col cols="12">
              <text-field-label
                :label="$t('salepage.link.field.name.label')"
                required />
              <v-text-field
                v-model="form.shopurl"
                :placeholder="$t('salepage.link.field.name.placeholder')"
                :error-messages="error.shopurl"
                dense
                outlined
                required
                @input="markAsDirty($event)"
              />
            </v-col>
            <v-col cols="12">
              <div class="mb-2">
                {{ $t('salepage.link.field.name.hint') }}
              </div>
              <div class="primary--text mb-1">
                https://{{ form.shopurl }}.nativewhale.com
              </div>
              <div class="primary--text mb-1">
                https://www.{{ domainName }}/tracking/{{ form.shopurl }}
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog()"
        >
          {{ $t('app.btn.cancel') }}
        </v-btn>
        <save-change-button
          class="mr-1"
          :state="state"
          :disabled="!isDirty || !valid"
          @click="submit()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { GET_SETTING_SALEPAGE, UPDATE_SETTING } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      query: GET_SETTING_SALEPAGE,
      result ({ data: { setting } }) {
        this.form.shopurl = setting.shopurl
      }
    })
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      form: {
        shopurl: null
      },
      // rule: {
      //   shopurl: [
      //     (v) => !!v || this.$t('app.validate.required')
      //   ]
      // },
      error: {
        shopurl: []
      }
    }
  },
  computed: {
    domainName () {
      const host = window.location.hostname
      const parts = host.split('.')
      if (parts.length === 3) {
        return `${parts[1]}.${parts[2]}`
      } if (parts.length === 2) {
        return `${parts[0]}.${parts[1]}`
      }
      return parts[0]
    },
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    ...mapActions({
      setSalePageUrl: 'Setting/setSalePageUrl'
    }),
    closeDialog () {
      this.dialog = false
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        shopurl: []
      }
    },
    async submit () {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            shopurl: this.form.shopurl
          }
        }
      }).catch(() => {
        this.state = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state = 'error'
        } else {
          this.setSalePageUrl(this.form.shopurl)
          this.state = 'success'
          this.closeDialog()
        }
      }
    }
  }
}
</script>
