var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": true,
        "content-class": "snt-salepage-action__menu",
        "min-width": "135",
        origin: "center center",
        transition: "slide-y-transition",
        "offset-y": ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "snt-salepage-action__btn",
                      attrs: {
                        color: "primary",
                        small: "",
                        depressed: "",
                        outlined: ""
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-icon", { attrs: { size: "30" } }, [
                    _vm._v(" mdi-menu-down ")
                  ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        {
          staticClass: "snt-salepage-action",
          attrs: { outlined: "", dense: "" }
        },
        [
          _c("sale-page-theme", {
            attrs: {
              "item-id": _vm.itemId,
              "item-custom-config": _vm.itemCustomConfig
            }
          }),
          _c("sale-page-rename", {
            attrs: { "item-id": _vm.itemId, "item-name": _vm.itemDomain }
          }),
          _c("sale-page-domain", {
            attrs: {
              "item-id": _vm.itemId,
              "item-stock": _vm.itemStock,
              "item-custom-domain": _vm.itemCustomDomain
            }
          }),
          _c("sale-page-config", {
            attrs: {
              "item-id": _vm.itemId,
              "item-stock": _vm.itemStock,
              "item-custom-config": _vm.itemCustomConfig
            }
          }),
          _c("sale-page-un-publish-button", {
            attrs: { "item-id": _vm.itemId }
          }),
          _c("sale-page-delete-button", { attrs: { "item-id": _vm.itemId } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }