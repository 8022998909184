import { render, staticRenderFns } from "./SalePageTheme.vue?vue&type=template&id=50c5fe6e&scoped=true&"
import script from "./SalePageTheme.vue?vue&type=script&lang=js&"
export * from "./SalePageTheme.vue?vue&type=script&lang=js&"
import style0 from "./SalePageTheme.vue?vue&type=style&index=0&id=50c5fe6e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50c5fe6e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VForm,VIcon,VListItem,VListItemSubtitle,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50c5fe6e')) {
      api.createRecord('50c5fe6e', component.options)
    } else {
      api.reload('50c5fe6e', component.options)
    }
    module.hot.accept("./SalePageTheme.vue?vue&type=template&id=50c5fe6e&scoped=true&", function () {
      api.rerender('50c5fe6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/SalePage/components/SalePageTheme.vue"
export default component.exports